import React from 'react'
import { Link } from 'gatsby'
// import Img from 'gatsby-image'
import Intro from '../components/intro'

const IndexPage = ({ data }) => (
  <Intro>
    <Link to="/start">
      <span>Touch Screen to Start</span>
    </Link>
  </Intro>
)

// export const query = graphql`
//   query {
//     file(relativePath: { regex: "/gilcrease_moran_intro.jpg/" }) {
//       childImageSharp {
//         fluid(maxWidth: 684) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

export default IndexPage
