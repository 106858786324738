import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'

const Intro = ({ title, children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
          {/* <link rel="stylesheet" href="https://use.typekit.net/wvg0bbi.css" /> */}
          <link rel="stylesheet" href="https://use.typekit.net/keo7ant.css" />
          <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
        </Helmet>
        <main className="intro">
          {title}
          {children}
        </main>
      </>
    )}
  />
)

Intro.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Intro
